@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* #region Theme Colors */
  html.light {
    --color-bg-primary: #fff;

    --color-border-input: #dbdbdb;
    --color-border-input-focus: #909090;
    --color-placeholder-input: #b6b6b6;
  }

  html.dark {
    --color-bg-primary: #131217;

    --color-border-input: #2a2c34;
    --color-border-input-focus: #676b7e;
    --color-placeholder-input: #676b7e;
  }

  html.dark .shiki,
  html.dark .shiki span {
    color: var(--shiki-dark) !important;
    background-color: var(--shiki-dark-bg) !important;
    font-style: var(--shiki-dark-font-style) !important;
    font-weight: var(--shiki-dark-font-weight) !important;
    text-decoration: var(--shiki-dark-text-decoration) !important;
  }
  /* #endregion Theme Colors */

  html {
    font-size: 70%;
  }

  html,
  body {
    @apply m-0 p-0 font-inter;
  }

  body {
    @apply bg-primary text-md;
  }

  * {
    @apply relative box-border;
  }

  a {
    color: inherit;
    width: fit-content;
    @apply inline-block no-underline;
  }

  article {
    @apply inline-block;
  }

  ::view-transition-group(root) {
    animation-duration: 200ms;
  }
}

@layer components {
  #taieri {
    background-image: url("/taieri.svg");
    background-repeat: repeat-y;
  }

  .dark #taieri {
    background-image: url("/taieri-dark.svg");
    background-repeat: repeat-y;
  }

  .disable-transition * {
    transition: none !important;
  }

  .navbar-backdrop {
    backdrop-filter: blur(50px) saturate(300%);
    background-color: rgba(255, 255, 255, 0.65);
  }
  .dark .navbar-backdrop {
    background-color: rgba(19, 18, 23, 0.8);
  }

  .animate-audio {
    animation: audiowave 800ms ease infinite alternate-reverse;
  }
  .animate-audio:nth-child(2) {
    animation-delay: 200ms;
  }
  .animate-audio:nth-child(3) {
    animation-delay: 300ms;
  }
  @keyframes audiowave {
    from {
      transform: scale(0.2);
    }
    to {
      transform: scale(1);
    }
  }

  .github-graph-container {
    container-name: github-graph-container;
    container-type: inline-size;
  }

  .github-graph-compact:nth-child(-n + 42) {
    @apply hidden;
  }

  /* 250px % 0.7 * 16px = ~22rem */
  @container github-graph-container (min-width: 22rem) {
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 36) {
      @apply block;
    }
  }
  /* 275px */
  @container github-graph-container (min-width: 24rem) {
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 29) {
      @apply block;
    }
  }
  /* 300px */
  @container github-graph-container (min-width: 26rem) {
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 22) {
      @apply block;
    }
  }
  /* 315px */
  @container github-graph-container (min-width: 28rem) {
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 15) {
      @apply block;
    }
  }
  /* 330px */
  @container github-graph-container (min-width: 29rem) {
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 8) {
      @apply block;
    }
    .github-graph-compact:nth-child(-n + 42):nth-child(n + 0) {
      @apply block;
    }
  }

  .github-graph-compact:nth-child(113):not(.github-graph-no-today) {
    @apply rounded-tr-[.6rem];
  }
  .github-graph-compact:nth-child(119):not(.github-graph-no-today) {
    @apply rounded-br-[.6rem];
  }
  @container github-graph-container (max-width: 22rem) {
    .github-graph-compact:nth-child(43) {
      @apply rounded-tl-[.6rem];
    }
    .github-graph-compact:nth-child(49) {
      @apply rounded-bl-[.6rem];
    }
  }
  @container github-graph-container (max-width: 24rem) {
    .github-graph-compact:nth-child(36) {
      @apply rounded-tl-[.6rem];
    }
    .github-graph-compact:nth-child(42) {
      @apply rounded-bl-[.6rem];
    }
  }
  @container github-graph-container (max-width: 26rem) {
    .github-graph-compact:nth-child(29) {
      @apply rounded-tl-[.6rem];
    }
    .github-graph-compact:nth-child(35) {
      @apply rounded-bl-[.6rem];
    }
  }
  @container github-graph-container (max-width: 28rem) {
    .github-graph-compact:nth-child(22) {
      @apply rounded-tl-[.6rem];
    }
    .github-graph-compact:nth-child(28) {
      @apply rounded-bl-[.6rem];
    }
  }
  @container github-graph-container (max-width: 29rem) {
    .github-graph-compact:nth-child(15) {
      @apply rounded-tl-[.6rem];
    }
    .github-graph-compact:nth-child(21) {
      @apply rounded-bl-[.6rem];
    }
  }
  .github-graph-compact:nth-child(1) {
    @apply rounded-tl-[.6rem];
  }
  .github-graph-compact:nth-child(7) {
    @apply rounded-bl-[.6rem];
  }

  /* #region MARKDOWN */
  .shiki .terminal-highlighted > span {
    @apply !text-gray-400 dark:!text-gray-500;
  }
  .shiki span.line.highlighted {
    @apply !bg-gray-50 *:!bg-gray-50 dark:!bg-[#1c1a29] dark:*:!bg-[#1c1a29];
  }
  /* #endregion MARKDOWN */
}
